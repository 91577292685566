import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="p-8">
      <strong>Deleting user data:</strong>
      <p>To request the deletion of your personal data, please send a request to: <strong>support@valencarte.com</strong>.</p>
      <p>Include the the subject <strong>"Request deletion"</strong> and request from the email account used to create the account.</p>
    </div>
  </Layout>
)

export default PrivacyPage
